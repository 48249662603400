<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <div class="header-button mb-1">
                <el-button @click="teacherType ? showDialog() : error()" type="primary" size="small">
                    新增教学信息采集
                </el-button>
                <el-button @click="showSubDialog('visibleJobDutyDialog', 'dialogJobDutyRef', formData.id)" type="primary"
                    size="small">
                    编辑岗位职责
                </el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column type='index' label='ID' width='50' />
            <el-table-column prop="gradeName" label="学年" min-width="90"></el-table-column>
            <el-table-column prop="term" label="学期" min-width="80">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term}学期` : '' }}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="teacherTermTimeRecord" label="出勤天数">
                <template v-slot="{ row }">
                    {{ row.teacherTermTimeRecord ? `${row.teacherTermTimeRecord.workDayNum}天` : '' }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="teacherTermTimeRecord" label="旷工天数">
                <template v-slot="{ row }">
                    {{ row.teacherTermTimeRecord ? `${row.teacherTermTimeRecord.missDayNum}天` : '' }}
                </template></el-table-column>
            <el-table-column show-overflow-tooltip prop="teacherTermTimeRecord" label="请假天数">
                <template v-slot="{ row }">
                    {{ row.teacherTermTimeRecord ? `${row.teacherTermTimeRecord.leaveDayNum}天` : '' }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="workResult" label="主要工作成绩" min-width="110"></el-table-column>
            <el-table-column show-overflow-tooltip prop="selfOpinion" label="自我诊断意见" min-width="120"></el-table-column>
            <el-table-column show-overflow-tooltip prop="improveWayResult" label="改进措施及成效"
                min-width="120"></el-table-column>
            <el-table-column show-overflow-tooltip prop="stuEmergencyRecord" label="(学生)突发事件处理记录"
                min-width="180"></el-table-column>
            <el-table-column show-overflow-tooltip prop="awards" label="个人获奖情况" min-width="110"></el-table-column>
            <el-table-column show-overflow-tooltip prop="nextTermPlan" label="下学期工作计划" min-width="120"></el-table-column>
            <el-table-column prop="courseRecord" label="教育教学"></el-table-column>
            <el-table-column v-if="(teacherType === '行政人员') || (teacherType === '中层干部')" prop="goOutWork"
                label="外出公干"></el-table-column>
            <el-table-column v-if="teacherType === '辅导员'" prop="classCollectSituation" label="班级汇总"></el-table-column>
            <el-table-column v-if="teacherType === '辅导员'" prop="classArrearSituation" label="欠费汇总"></el-table-column>
            <el-table-column prop="articleRecord" label="稿件"></el-table-column>
            <el-table-column prop="projectResult" label="课题"></el-table-column>
            <el-table-column prop="paperResult" label="论文"></el-table-column>
            <el-table-column v-if="teacherType === '教师'" prop="work" label="著作"></el-table-column>
            <el-table-column prop="patent" label="专利"></el-table-column>
            <el-table-column prop="guidedCompetitionAwards" label="竞赛"></el-table-column>
            <el-table-column prop="socialPracticeTrain" label="社会实践"></el-table-column>
            <el-table-column v-if="teacherType !== '辅导员'" prop="socialService" label="社会服务"></el-table-column>
            <el-table-column v-if="teacherType !== '行政人员'" prop="studentActivity" label="学生活动"></el-table-column>
            <el-table-column v-if="teacherType === '教师'" prop="supervisedGraduateInternship" label="毕业生顶岗实习"
                min-width="120"></el-table-column>
            <el-table-column label="操作" min-width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        编辑
                    </el-button>
                    <el-button type="text"
                        @click="del(delPersonCollectByIdApi, row.id, `${row.gradeName} 第${row.term}学期 教学信息`)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-add-person-collect ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog'
            @on-close='renderTable' />
        <dialog-job-duty ref='dialogJobDutyRef' :visible.sync='visibleJobDutyDialog' v-if='visibleJobDutyDialog' />
    </table-view>
</template>

<script>
import DialogAddPersonCollect from './components/DialogAddPersonCollect.vue'
import DialogJobDuty from './components/DialogJobDuty'
import { pagePersonCollecDescCurrentUserApi, delPersonCollectByIdApi } from '@/api/infoCollect/personCollect-api.js'
import { getInfoCollectByUserId } from '@/api/infoCollect/infoCollect-api.js'

import tableView from '@/vue/mixins/table-view'

export default {
    mixins: [tableView],
    components: {
        DialogAddPersonCollect,
        DialogJobDuty
    },
    data() {
        return {
            formData: {},
            teacherType: '',
            visibleJobDutyDialog: false
        }
    },
    watch: {
        visibleDialog: function (newVal, oldVal) {
            if (newVal === false && oldVal === true) {
                this.renderTable()
            }
        }
    },
    mounted() {
        this.renderTable()
        this.getNowTeacherType()
    },
    methods: {
        delPersonCollectByIdApi,
        async renderTable(pageNum) {
            await this.getTableData(pagePersonCollecDescCurrentUserApi, pageNum)
        },
        // 获取当前教师身份
        getNowTeacherType() {
            const userId = this.$store.state.login.userInfo.id
            getInfoCollectByUserId(userId).then((res) => {
                this.teacherType = res.data.teacherType
            })
        },
        // 警告信息
        error() {
            this.$message.error('获取职工类别失败，请前往用户设置填写！')
        }
    }

}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
